.flex-container100 {
  display: flex;
  flex-direction: column;
  height: 768px;
  min-height: 768px; }

.flex-container50 {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  width: 50%;
  height: 100%; }

.header {
  background: #fff !important; }

.svg-image {
  height: auto;
  width: auto; }

.mdl-layout__header-row .mdl-navigation__link {
  color: black !important; }

.services-img {
  width: 1368px;
  height: 768px; }

.couple-img {
  width: 876x;
  height: 595x;
  -webkit-clip-path: polygon(0 1%, 100% 0, 40% 100%, 0% 100%);
  clip-path: polygon(0 1%, 100% 0, 40% 100%, 0% 100%); }

.content-text {
  display: flex;
  flex-direction: row; }

.sevices-background-section1 {
  display: flex;
  background-color: #87A6AB; }

.content-right-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 70px; }

.flex-container100 {
  display: flex;
  flex-direction: column;
  height: 768px;
  min-height: 768px; }

.flex-container50 {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  width: 50%;
  height: 100%; }

.header {
  background: #fff !important; }

.svg-image {
  height: auto;
  width: auto; }

.mdl-layout__header-row .mdl-navigation__link {
  color: black !important; }

.services-img {
  width: 1368px;
  height: 768px; }

.couple-img {
  width: 876x;
  height: 595x;
  -webkit-clip-path: polygon(0 1%, 100% 0, 40% 100%, 0% 100%);
  clip-path: polygon(0 1%, 100% 0, 40% 100%, 0% 100%); }

.content-header {
  display: flex; }

.content-text {
  display: flex;
  flex-direction: row; }

.sevices-background-section1 {
  display: flex;
  background-color: #87A6AB; }

.content-right-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 70px; }

/*******************************
         react-overrides
  *******************************/
.mdl-layout__header-row .mdl-navigation__link {
  color: black !important; }

footer {
  background-color: #daa09a !important;
  color: black !important; }

.header {
  background: #fff !important; }

/* Navbar container */
.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

/*******************************
         Mixins
  *******************************/
/*******************************
         Helper Classes
  *******************************/
/* margins */
/* margins */
.margin-bottom-min15 {
  margin-bottom: -15px; }

.margin-left-1per {
  margin-left: 1%; }

.margin-left-4per {
  margin-left: 4%; }

.margin-left-6per {
  margin-left: -6%; }

.margin-left-4sm {
  margin-left: 0%; }

.margin-left-6 {
  margin-left: 6%; }

.margin-left-6sm {
  margin-left: 0%; }

.margin-left-3 {
  margin-left: 0%; }

.margin-left-5 {
  margin-left: 5%; }

.margin-left-5sm {
  margin-left: 0%; }

.margin-left-min5sm {
  margin-left: 0%; }

.margin-left-10sm {
  margin-left: 0%;
  margin-top: 0px; }

.margin-left-8 {
  margin-left: 8%; }

.margin-left-9 {
  margin-left: 9%; }

.margin-left-8sm {
  margin-left: 0%; }

.margin-left-18 {
  margin-left: 18%; }

.margin-left-18per {
  margin-left: -18%; }

.margin-left-10sm {
  margin-left: 0%; }

.margin-left-4sm {
  margin-left: 0%; }

.margin-left-12per {
  margin-left: 12%; }

.margin-left-min12per {
  margin-left: -12%; }

.margin-left-min10per {
  margin-left: -10%; }

.margin-left-15 {
  margin-left: 15px; }

.margin-left-15per {
  margin-left: 15%; }

.margin-left-15sm {
  margin-left: 0%; }

.margin-left-min15sm {
  margin-left: 0%; }

.margin-top-35sm {
  margin-top: 0%; }

.margin-left-30per {
  margin-left: 30%; }

.margin-left-min30per {
  margin-left: -30%; }

.margin-left-32 {
  margin-left: -32%; }

.margin-left-32persm {
  margin-left: 0%; }

.margin-left-10per {
  margin-left: 10%; }

.margin-left-min10per-sm {
  margin-left: 0%; }

.margin-top-10persm {
  margin-top: 0%; }

.margin-left-20per {
  margin-left: 20%; }

.margin-left-min20per {
  margin-left: -20%; }

.margin-left-22per {
  margin-left: 22%; }

.marginleft30 {
  margin-left: 30px; }

.marginleft40per {
  margin-left: 40%; }

.marginleft-40per {
  margin-left: -40%; }

.margin-top-1 {
  margin-top: 1%; }

.margin-top-1per {
  margin-top: -1%; }

.margintop-4per {
  margin-top: -4%; }

.marginleft-minus50 {
  margin-left: -50px; }

.marginleft-minus300 {
  margin-left: -300px; }

.marginleft50 {
  margin-left: 50px; }

.margin-left-325 {
  margin-left: 325px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-50cs {
  margin-top: 50px; }

.margin-top-50sm {
  margin-top: 0%; }

.margin-top-70 {
  margin-top: 70px; }

.margin-left-2sm {
  margin-left: 0%; }

.margin-left-2persm {
  margin-left: 0%; }

.margin-left-min2sm {
  margin-left: -2%; }

.margin-left-2 {
  margin-left: 2%; }

.margin-left-min40 {
  margin-left: -40%; }

.margin-left-50 {
  margin-left: 50%; }

.margin-left-60per {
  margin-left: 60%; }

.margin-left-25per {
  margin-left: -25%; }

.margin-left-25sm {
  margin-left: 0%; }

.margin-left-min25sm {
  margin-left: 0%; }

.margin-right-50 {
  margin-right: 50%; }

.margin-right-50 {
  margin-right: 0%; }

.margin-right-25sm {
  margin-right: 25%; }

.margin-right-50sm {
  margin-right: 50%; }

.margin-left-140 {
  margin-left: 140px; }

.margin-top-25sm {
  margin-top: 0; }

.margin-top-30sm {
  margin-top: 0; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-container100 {
  display: flex;
  flex-flow: column;
  height: 768px; }

.flex-container-50 {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  width: 50%;
  height: 100%; }

.flex-justify {
  display: flex;
  justify-content: space-between;
  width: 0px; }

.flex-items-align-top {
  align-items: flex-start; }

/*******************************
         Spacer Classes
  *******************************/
.margin-top-2 {
  margin-top: 2px; }

.margin-top-7per {
  margin-top: -7%; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-10sm {
  margin-top: 0px; }

.margin-top-15sm {
  margin-top: 0px; }

.margin-top-75 {
  margin-top: 75px; }

.margin-top-75per {
  margin-top: -75%; }

.margin-top-100 {
  margin-top: 100px; }

.margin-top-150 {
  margin-top: 150px; }

.margin-top-200 {
  margin-top: 200px; }

.margin-top-5per {
  margin-top: -5%; }

.margin-top-plus5per {
  margin-top: 0%; }

.margin-top-10per {
  margin-top: -10%; }

.margin-top-plus3per {
  margin-top: 3%; }

.margin-top-min10per {
  margin-top: 0%; }

.margin-top-75sm {
  margin-top: 0px; }

.margin-top-min25sm {
  margin-top: 0px; }

.margin-top-15per {
  margin-top: -15%; }

.margin-top-14per {
  margin-top: 14%; }

.margin-top-2per {
  margin-top: 2%; }

.margin-top-25per {
  margin-top: -.25%; }

.margin-left-50 {
  margin-left: 50%; }

.margin-top-20per {
  margin-top: -20%; }

.margin-top-20 {
  margin-top: 20px; }

.margin-left-75 {
  margin-left: 75px; }

.margin-bottom-75 {
  margin-bottom: 75px; }

.margin-right-25 {
  margin-right: 25%; }

.margin-right-55 {
  margin-right: 55%; }

.margin-right-35 {
  margin-right: 35%; }

.margin-right-5 {
  margin-right: 5%; }

.align-self-auto {
  align-self: auto; }

.hrline-spacing {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-tb-100 {
  margin: 100px 0 100px; }

.margin-tb-75 {
  margin: 75px 0 75px; }

.margin-tb-50 {
  margin: 50px 0 50px; }

.padding-top-8sm {
  padding-top: 8%; }

.padding-10 {
  padding: 10px; }

.padding-top-6sm {
  padding-top: 0%; }

.padding-top-50 {
  padding-top: 50px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

.padding-bottom-100 {
  padding-bottom: 100px; }

.padding-bottom-150 {
  padding: 150px; }

.padding-left-4 {
  padding-left: 4%; }

.padding-left-15 {
  padding-left: 15%; }

.padding-20 {
  padding: 20px; }

.padding-75 {
  padding: 75px; }

.flex-align-top {
  align-items: flex-start; }

.flex-layout-section-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 605px; }

.flex-layout-section {
  display: flex;
  flex-direction: column;
  height: 725px; }

.flex-section-fixed-head {
  display: flex;
  height: 100%;
  min-height: 590px;
  background-color: #6c8086; }

.flex-section-fixed-head-child {
  display: flex;
  height: 100%;
  background-color: #e3ce71; }

.flex-layout-section-row {
  display: flex;
  flex-direction: row;
  height: 605px; }

.flex-layout-section-full {
  display: flex;
  flex-direction: column;
  height: auto; }

.flex-layout-section-full-cs {
  display: flex;
  width: 1200px;
  align-content: center;
  flex-direction: column;
  height: auto; }

.flex-layout-section-fixed {
  display: flex;
  height: 668px; }

.float-right {
  float: right;
  padding: 15px 7px 15px 30px; }

.float-left {
  float: left;
  padding: 15px 5px 15px 15px; }

.float-left-np {
  float: left;
  padding: 0px; }

.flex-item-bottom {
  align-self: flex-end; }

.align-self-center {
  align-self: center; }

.justify-self-center {
  justify-self: center; }

.justify-self-end {
  justify-self: end; }

.flex-container50 img {
  width: 200px;
  height: 235px;
  min-width: 200px;
  max-height: 235px;
  margin-right: 20px; }

.flex-layout-vertical {
  display: flex;
  flex-direction: column; }

.flex-layout-center-center {
  justify-content: center;
  align-items: center; }

.flex-items-justified-center {
  justify-content: center; }

.flex-layout-column-reverse {
  display: flex;
  flex-direction: column-reverse; }

.flex-sh-500 {
  flex: 0 1 624px; }

.flex-sh-400 {
  flex: 0 1 400px; }

/* Add these classes to respective partial files */
.flex-container-default {
  display: flex; }

.flex-layout-justified-end {
  display: flex;
  justify-content: flex-end; }

.flex-layout-justified-center {
  display: flex;
  justify-content: center; }

.flex-layout-justified-sb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.flex-layout-justified-sb-nw {
  display: flex;
  justify-content: space-between; }

.flex-layout-justified-sa {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap; }

.flex-layout-row {
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: nowrap; }

.flex-layout-row-reverse {
  display: flex;
  flex-direction: row-reverse; }

.flex-layout-column {
  display: flex;
  flex-direction: column; }

.flex-layout-column-half {
  display: flex;
  flex-direction: column;
  width: 50%; }

.width-half {
  width: 50%; }

.width-10 {
  width: 10%; }

.width-quarter {
  width: 25%; }

.width-75 {
  width: 75%; }

.section-min-width {
  min-width: 500px; }

.content-header {
  font-family: Roboto Condensed, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0; }

.content-block-half {
  width: 50%; }

.content-block-auto {
  width: auto; }

.content-block-10 {
  width: 10%; }

.content-block-20 {
  width: 20%; }

.content-block-25 {
  width: 25%; }

.content-block-35 {
  width: 35%; }

.content-block-40 {
  width: 43%; }

.content-block-40-co {
  width: 40%; }

.content-block-45 {
  width: 45%; }

.content-block-50 {
  width: 50%; }

.content-block-55 {
  width: 55%; }

.content-block-60 {
  width: 60%; }

.content-block-65 {
  width: 65%; }

.content-block-75 {
  width: 75%; }

.content-block-80 {
  width: 80%; }

.content-block-85 {
  width: 85%; }

.content-block-90 {
  width: 90%; }

/*  Alignment   */
.align-items-center {
  align-items: center; }

.align-self-center {
  align-self: center; }

.container-section-1-primary {
  display: flex;
  height: 270px; }

.container-section-1-primary {
  display: flex;
  height: 270px; }

.container-justifyRow-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; }

.flex-layout-right-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.flex-layout-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.flex-content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 500px;
  margin-left: 70px; }

.flex-content-container-split {
  display: flex;
  flex-direction: column;
  flex: 1 1 500px; }

.flex-card-content {
  display: flex;
  padding: 0 15px 5px 15px; }

.flex-image-container {
  display: flex;
  flex-direction: flex-start;
  flex: 1 1 420px;
  border-top: none;
  border-bottom: none; }

.flex-image-container-master {
  display: flex;
  flex-direction: flex-start;
  flex: 1 1 420px; }

.flex-image-container-child {
  display: flex;
  flex-direction: flex-start;
  flex: 1 0 auto; }

.flex-image-container-overlay {
  display: flex;
  flex-direction: flex-start;
  flex: 1 1 420px; }
  .flex-image-container-overlay > a .overlay-yellow {
    position: absolute;
    width: 128px;
    height: 128px;
    transition: background-color .3s ease-in-out;
    background-color: rgba(251, 180, 36, 0.4); }
    .flex-image-container-overlay > a .overlay-yellow:hover {
      background-color: rgba(251, 180, 36, 0);
      cursor: pointer; }

.flex-layout-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start; }

.photo-container {
  width: 100%;
  height: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px; }

.photo-container-cheer {
  width: 550px;
  height: 333px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px; }

.photo-container-callout {
  width: 550px;
  height: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px; }

.photo-container-feature {
  width: 550px;
  height: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px; }

.photo-container-cs {
  width: 550px;
  height: 333px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px; }

.container-image-block-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; }

.container .mdl-js-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center; }

.container-image-block {
  display: flex;
  width: 100%; }

.caption {
  text-align: center;
  font-size: .875rem;
  font-style: italic; }

.caption-left {
  font-size: .875rem;
  font-style: italic; }

/*  Layers and Sizes   */
.width-100 {
  width: 100%; }

.width-93 {
  width: 93%; }

.width-110 {
  width: 110%; }

.width-76sm {
  width: 0; }

.width-25 {
  width: 25%; }

.width-50 {
  width: 50%; }

.width-60 {
  width: 60%; }

.width-50px {
  width: 50px; }

.width-280px {
  width: 280px; }

.width-26per {
  width: 26%; }

.width-40 {
  width: 40%; }

.width-icon {
  width: 100%;
  max-width: 100px; }

.height-70 {
  height: 70%; }

.layer-bottom {
  z-index: 1;
  position: absolute; }

.layer-top {
  display: flex;
  z-index: 10;
  position: absolute;
  flex-direction: row;
  align-items: center; }

.width-70 {
  width: 60%; }

.floatleft {
  float: left; }

.image-background-border-tb {
  display: flex;
  flex: 1 1 100%;
  border-top: 5px solid #000;
  border-bottom: 5px solid #000; }

.border-tb {
  display: flex;
  flex: 1 1 90%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-left: 240px;
  margin-right: 240px; }

.border-tb-5 {
  border-top: 5px solid #000000;
  border-bottom: 5px solid #000000; }

.border-bottom-5 {
  border-bottom: 5px solid #000; }

.border-top-5 {
  border-top: 5px solid #000; }

.border-bottom-1 {
  border-bottom: 1px solid #000; }

.border-top-1 {
  border-top: 1px solid #000;
  padding-top: 100px; }

.border-2 {
  border: 2px solid #000;
  box-shadow: 2px 4px 3px #dfdfdf; }

.servicespic {
  max-height: 500px;
  margin-right: 20px; }

.services-img {
  width: 1368px;
  height: 768px; }

.marginleft30 {
  margin-left: 30px; }

.marginleft50 {
  margin-left: 50px; }

.marginleft50 {
  margin-left: 50px; }

.margin-bottom-10sm {
  margin-bottom: 10px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-25sm {
  margin-bottom: 0px; }

.media-margin {
  margin-left: 8%; }

.media-left-margin {
  margin-left: 1%; }

.list1 {
  margin-top: 15px;
  margin-left: 19%; }

.margin-top-min50per-sm {
  margin-top: 0%; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-100 {
  margin-bottom: 100px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-min10 {
  margin-top: -14px; }

.margin-top-15 {
  margin-top: 15px; }

.margin-top-min15 {
  margin-top: -15px; }

.margin-top-25 {
  margin-top: 25px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-100 {
  margin-top: 100px; }

.margin-top-150 {
  margin-top: 150px; }

.margin-top-200 {
  margin-top: 200px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

.padding {
  padding: 50px; }

.padding-sm {
  padding: 0px; }

.padding-sm-20-10 {
  padding: 0px; }

.padding-right-10 {
  padding-right: 10px; }

.padding-right-38 {
  padding-right: 38%; }

.padding-right-15 {
  padding-right: 15%; }

.bottom-callout-cs {
  width: 70%;
  margin-top: 50;
  margin-bottom: 50;
  align-self: center; }

.content-text {
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.88; }

.content-text-21 {
  font-family: 'Roboto', 'sans serif';
  font-size: 1.3125rem;
  line-height: 1.88;
  text-align: left; }

.content-text-18 {
  font-family: 'Roboto', 'sans serif';
  font-size: 1.2rem;
  line-height: 1.88;
  min-width: 200px; }

.content-text-24 {
  font-family: 'Roboto', 'sans serif';
  font-size: 1.6rem;
  line-height: 1.5; }

.content-text-36 {
  font-family: 'Roboto', 'sans serif';
  font-size: 1.6rem;
  line-height: 1.5; }

.content-text-48 {
  font-family: 'Roboto', 'sans serif';
  font-size: 3.2rem;
  line-height: 1.5; }

.header-title {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 10.1875rem;
  letter-spacing: 1.1px;
  line-height: 1.4;
  text-align: center; }

.header-title-sm-center {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 2.75rem;
  letter-spacing: 1.1px;
  line-height: 1.4;
  text-align: center; }

.header-title-sm {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 3.1875rem;
  letter-spacing: 1.1px;
  line-height: 1.4;
  text-align: left; }

.header-title-cs {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 3.75rem;
  letter-spacing: 1.1px; }

.title-homepage {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 2.12rem;
  letter-spacing: 1.1px; }

.text-white {
  color: #fff; }

li {
  display: list-item;
  text-align: -webkit-match-parent; }

.card-case-studies {
  box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 650px; }

.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

/*******************************
         Global
  *******************************/
* {
  -webkit-overflow-scrolling: touch; }

html {
  scroll-behavior: smooth; }

body {
  font-size: 16px;
  display: flex;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "poppins" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff; }

/*******************************
         Global Text
  *******************************/
h1 {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1.236rem !important; }

h2 {
  font-family: 'Roboto', sans-serif !important;
  font-size: 2.618rem !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

h3 {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1.618rem !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

h4 {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.218rem !important; }

.header-text-left {
  float: left;
  position: absolute;
  left: 7%; }

.header-text-right {
  float: right;
  position: absolute;
  right: 7%; }

.header-text-center {
  display: flex;
  position: absolute;
  justify-self: center; }

.justify-items-center {
  justify-items: center; }

a.greenlink {
  color: #3eaca9 !important; }

a {
  color: black; }

a:hover {
  color: black;
  cursor: pointer; }

.no-decoration {
  color: none;
  text-decoration: none; }

.no-decoration-black {
  color: black;
  text-decoration: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul {
  margin: 0;
  list-style-type: none;
  padding: 0; }

li {
  margin: 0; }

ul.dashed {
  list-style: none;
  padding-left: 0;
  font-family: 'Roboto'; }

ul.dashed > li {
  margin-left: 20px;
  margin-bottom: 5px;
  text-align: left;
  font-family: 'Roboto' sans-serif;
  font-weight: 400; }

/* Prevent nested li's from getting messed up */
ul.dashed > li::before {
  content: " -   ";
  margin-left: -15px; }

h1 {
  font-family: 'RobotoCondensed-Bold', sans-serif; }

.callout-text {
  font-size: 1.5625rem;
  font-family: 'Roboto', sans-serif;
  line-height: 2.12;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-align: center;
  margin-top: 0px;
  color: #000; }

.callout-title {
  font-size: 2rem;
  font-family: 'Roboto Condensed', serif;
  line-height: 2.25rem;
  letter-spacing: 0.8px;
  font-weight: 700;
  text-align: left;
  color: #000; }

.callout-text-lg-black {
  font-size: 2rem;
  font-family: 'Roboto Condensed', serif;
  line-height: 2.12;
  letter-spacing: 0.8px;
  font-weight: 700;
  text-align: center;
  color: #000; }

.callout-text-lg {
  font-size: 2rem;
  font-family: 'Roboto Condensed', serif;
  line-height: 2.12;
  letter-spacing: 0.8px;
  text-align: center;
  color: #fff;
  font-weight: 700; }

.callout-text-white {
  font-size: 1.25rem;
  font-family: 'Roboto Bold', sans-serif;
  line-height: 2;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-align: left;
  color: #fff; }

.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3.05rem;
  line-height: 1.25;
  color: #879397;
  opacity: .8;
  text-align: left; }

.title-white {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3.05rem;
  line-height: 1.25;
  color: #fff;
  opacity: .8;
  text-align: left; }

.title-center {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3.05rem;
  font-weight: 500;
  color: #879397;
  opacity: .8;
  line-height: 1.25px;
  text-align: center; }

.title-center-sm {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  color: #879397;
  opacity: .8;
  line-height: 37.75px;
  text-align: center; }

.subtitle {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.45;
  font-family: 'Roboto Condensed';
  color: #879397;
  text-align: left; }

.subtitle-center {
  font-size: 1.8rem;
  line-height: 1.25;
  font-family: 'Roboto Condensed';
  color: #879397;
  text-align: center; }

.height-670 {
  height: 670px;
  min-height: 400px; }

.subtitle-bold-i {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  font-style: italic; }

.subtitle-menu {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2; }

.subtitle-capital {
  font-size: 2.44375rem;
  font-weight: 700;
  line-height: 1.8;
  text-transform: capitalize; }

.subtitle-reverse {
  font-size: 2.44375rem;
  font-weight: 700;
  line-height: 1.8;
  color: #fff; }

.title-small {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3eaca9;
  font-family: 'Roboto', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; }

.font-size-48 {
  font-size: 2.6rem; }

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  margin-bottom: 15px;
  padding: 0;
  font-family: Roboto; }

.text-align-center {
  text-align: center; }

.text-align-center-sm {
  text-align: none; }

.text-black {
  color: #000; }

.text-bold {
  font-weight: bold; }

.text-white {
  color: #fff; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

.hr-line {
  display: block;
  height: 1px;
  border-top: 1px solid rgba(106, 106, 106, 0.25);
  margin-top: 75px;
  margin-bottom: 75px;
  width: 60%;
  align-self: center; }

.hr-line-cs {
  display: block;
  height: 1px;
  border-top: 1px solid rgba(106, 106, 106, 0.25);
  margin-top: 75px;
  margin-bottom: 75px;
  width: 65%;
  align-self: center; }

.container-card-content {
  height: auto;
  margin: -10px 10px 5px 10px; }

.content-container-cs {
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center; }

.container-photo-100 {
  width: 100%;
  height: auto; }

/*******************************
       Footer
  *******************************/
.container-footer {
  display: flex;
  align-items: center;
  background-color: #EC1000; }

footer a {
  color: white !important;
  text-decoration: none; }

footer a :hover {
  color: black;
  text-decoration: underline; }

footer.link-color {
  color: #000; }

/*******************************
        Global Colors
  *******************************/
.green-blue {
  background-color: #3eaca9; }

.red {
  color: #EC1000; }

.grey {
  background-color: #6c8086; }

.ltgrey {
  background-color: #a4a4a4; }

.grey-blue {
  background-color: #a6bbc0b3; }

.border-2-sm {
  border: 0; }

/*******************************
       Global Images
  *******************************/
.image-size-10 {
  height: 10%;
  width: auto; }

.image-size-20 {
  height: 20%;
  width: auto; }

.image-size-30 {
  height: 30%;
  width: auto; }

.image-size-50 {
  height: 50%;
  width: auto; }

.image-size-75 {
  height: 75%;
  width: auto; }

.image-size-100 {
  height: 100%;
  width: 100%; }

.container-photo-callout {
  width: 650px;
  height: 450px;
  margin-left: 0%; }

.photo-callout-top {
  width: 650px;
  height: 450px;
  margin-left: 0%; }

.container-photo-50px {
  width: 50px;
  height: auto; }

.image-size-logo {
  width: 150px; }

.image-size-header-fixed {
  height: 1920px;
  width: 100%; }

input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical; }

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

input[type=submit]:hover {
  background-color: #45a049; }

.display-none {
  visibility: visible; }

.display-remove {
  display: flex; }

.material-icons {
  margin-top: 20px;
  display: none;
  opacity: 0.1;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

:root {
  font-size: 16px; }

ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px; }

.video {
  position: relative;
  width: 70%; }

.margin-left-25 {
  margin-left: 25%; }

.margin-left-25sm {
  margin-left: 4%; }

.margin-left-17 {
  margin-left: 17%; }

.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

/*******************************
           Shared
  *******************************/
.header {
  font-size: 1.5em;
  background-color: #008000; }

a:hover {
  text-decoration: underline;
  color: #3eaca9; }

.svg-image {
  height: auto;
  width: auto; }

.link-color {
  color: #3eaca9; }

.white {
  color: fff; }

.flex-layout-row {
  display: flex;
  flex-direction: row; }

.flex-layout-row-reverse {
  display: flex;
  flex-direction: row-reverse; }

.textblock {
  padding: 50px; }

.border-3px {
  border-color: black;
  border-width: 3px;
  border: solid; }

.bottom-border {
  border-bottom-style: outset;
  border-bottom-color: black;
  border-bottom-width: 5px; }

.flex-layout-content-container {
  display: flex;
  width: 850px; }

.flex-container-button {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 40px; }

.heading1 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  margin-bottom: 15px; }

Button {
  padding: 12px;
  border: #000 solid 1px .5;
  white-space: nowrap;
  margin: 5px;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  background-color: unset;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif; }

Button:hover {
  border: #000 solid 3px 1.0;
  background-color: #87A6AB;
  color: #fff;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }

.holder {
  margin-left: 0%;
  margin-right: 0%; }

.button-white {
  border: #fff solid 1px;
  color: #fff;
  white-space: nowrap;
  margin: 5px;
  font-weight: 500;
  font-size: 1.2em;
  display: inline-block;
  text-align: center; }

.button {
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #fff;
  border-radius: 1px;
  border: 1px solid #000000;
  border-width: 1px 1px 1px 1px;
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 1px 0px #ffffff, 0px 1px 3px rgba(0, 0, 0, 0.3);
  color: #010101;
  font-size: 16px;
  font-family: Helvetica Neue;
  font-weight: 500;
  font-style: normal;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4); }

.button > div {
  color: #999;
  font-size: 10px;
  font-family: Helvetica Neue;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px; }

.button > i {
  font-size: 1em;
  border-radius: 0px;
  border: 0px solid transparent;
  border-width: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  position: static; }

.button a {
  text-decoration: none;
  color: black !important; }

.button a:hover {
  border: #000 solid 3px 1.0;
  background-color: #87A6AB;
  color: #fff;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 0.7); }

.button-area {
  font-size: 1rem;
  font-family: 'Roboto';
  font-weight: 300;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #000;
  padding: 5px 30px 5px 30px;
  min-height: 25px;
  max-height: 25px;
  max-width: 100px;
  box-shadow: 0px 1px 0px #ffffff, 0px 1px 3px rgba(0, 0, 0, 0.3);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  color: #010101 !important;
  align-items: center; }

.button-area:hover {
  font-weight: 500;
  text-decoration: none;
  background-color: #87A6AB;
  color: #fff !important;
  transition-duration: .50s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }

.button-area > a {
  font-size: .9em;
  color: white;
  text-decoration: none;
  flex-grow: 1;
  text-align: center; }

.button-area-line {
  margin: 10px auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 90%; }
  .button-area-line a {
    background: #999;
    padding: 1em;
    font-size: .9em;
    margin: .3em;
    color: white;
    text-decoration: none;
    flex-grow: 1;
    text-align: center; }

/*  Alignment   */
.align-items-center {
  align-items: center;
  display: flex; }

.container-justifyRow-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%; }

.flex-layout-right-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.flex-layout-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.flex-layout-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 768px; }

.container-image-block-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; }

.container-image-block {
  display: flex;
  width: 100%; }

/*  Layers and Sizes   */
.width-100 {
  width: 100%; }

.width-50 {
  width: 50%; }

.height-70 {
  height: 70%; }

.height-100 {
  height: 100%; }

.layer-bottom {
  z-index: 1;
  position: absolute; }

.layer-top {
  display: flex;
  z-index: 10;
  position: absolute;
  flex-direction: row;
  align-items: center; }

.width-70 {
  width: 70%; }

.floatleft {
  float: left; }

/*******************************
          Grid
  *******************************/
.container-grid-content {
  display: grid;
  grid-template-columns: 1fr minmax(500px, 1fr) 1fr; }

.grid-content {
  grid-column: 2/3;
  grid-row: auto; }

.align-self-end {
  align-self: end; }

.grid-gap-20 {
  grid-gap: 20px; }

.grid-content-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px; }

.grid-title {
  grid-column: auto / span 2;
  align-items: center; }

.grid-title-3col {
  grid-column: auto / span 3;
  align-items: center; }

.grid-a {
  grid-column: auto;
  grid-row: auto; }

.grid-b {
  grid-column: auto;
  grid-row: auto; }

.grid-c {
  grid-column: auto;
  grid-row: 3/3; }

.grid-d {
  grid-column: auto;
  grid-row: 3/3; }

.container-grid {
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  display: grid; }

.grid-item-a-full {
  grid-column: span 12;
  grid-row: auto; }

.container-grid-cs {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 50px;
  align-self: center;
  display: grid; }

.grid-item-cs {
  grid-column: 1/1;
  grid-row: auto; }

.container-grid-2col {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
  align-self: center;
  display: grid;
  justify-content: space-around; }

.container-grid-2col-full {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  align-self: center;
  display: grid;
  justify-content: space-around; }

.grid-item-2a {
  grid-column: 1 / 2;
  grid-row: 1 / 4; }

.grid-item-2b {
  grid-column: 2 / 2;
  grid-row: 1 / span 4;
  align-items: center; }

.grid-item-2c {
  grid-column: 1 / 2;
  grid-row: 4 / 4; }

.grid-item-2d {
  grid-column: 2 / 2;
  grid-row: 6 / 8; }

.grid-item-2e {
  grid-column: 1 / 2;
  grid-row: 6 / 8; }

.container-grid-2col-photo {
  grid-template-columns: 600px 600px;
  grid-template-rows: auto;
  grid-gap: 10px;
  display: grid; }

.container-grid-3col {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2px;
  display: grid;
  align-items: baseline; }

.grid-3col {
  display: grid;
  grid-template-columns: 500px 500px 500px;
  grid-gap: 10px; }

.container-grid-4col {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
  display: grid; }

.container-grid-cs {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 2px;
  display: grid; }

.grid-item-a-self {
  grid-column: 1 / 2;
  grid-row: 1 / auto;
  align-items: center;
  align-self: center;
  justify-self: center; }

.grid-item-a {
  grid-column: 1 / 3;
  grid-row: 1 / auto;
  align-items: center;
  justify-self: center;
  align-self: center; }

.grid-item-b {
  grid-column: 2 / 3;
  grid-row: 1 / auto;
  align-items: center;
  justify-content: center;
  align-self: center; }

.grid-item-c {
  grid-column: 1 / 3;
  grid-row: 2 / auto;
  align-items: center; }

.grid-item-d {
  grid-column: 2 / 3;
  grid-row: 2/ auto;
  align-items: center; }

.grid-item-d4 {
  grid-column: 4 / 4;
  align-items: center; }

.grid-item-e {
  grid-column: 1 / 2;
  grid-row: 3 / auto;
  align-items: center; }

.grid-item-f {
  grid-column: 2 / 2;
  grid-row: 3/ auto;
  align-items: center; }

.item-a-nb {
  grid-column: 1 / 2;
  grid-row: 1 / auto;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center; }

.item-a {
  grid-column: 1 / 2;
  grid-row: 1 / auto;
  align-items: center;
  border-top: 2px solid #dfdfdf; }

.item-overlay {
  grid-column: 2 / 2;
  grid-row: 1 / auto;
  align-items: center;
  background-color: rgba(227, 206, 113, 0.8); }

.item-b {
  grid-column: 2 / 3;
  grid-row: 1 / auto;
  padding: 10px;
  justify-content: center;
  justify-self: center; }

.item-c {
  grid-column: 1 / 2;
  grid-row: 2 / auto;
  align-items: center;
  border-top: 2px solid #dfdfdf; }

.item-c-nb {
  grid-column: 1 / 2;
  grid-row: 2 / auto;
  align-items: center; }

.item-d {
  grid-column: 2 / 3;
  grid-row: 2 / auto;
  padding: 10px;
  justify-content: center; }

.item-e-nb {
  grid-column: 1 / 2;
  grid-row: auto;
  align-items: center;
  border-top: 2px solid #dfdfdf; }

.item-f {
  grid-column: 2 / 3;
  grid-row: auto;
  padding: 10px;
  justify-content: center; }

.grid-4col-text {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "text text text text";
  grid-column-gap: 50px;
  display: grid; }

.grid-2col-text {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text text";
  grid-template-rows: auto;
  grid-column-gap: 50px;
  grid-row-gap: 5px;
  display: grid; }

.grid-2col-text-center-50 {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text text";
  grid-template-rows: auto;
  grid-row-gap: 5px;
  width: 50%;
  display: grid; }

.grid-2col-text-center {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text text";
  grid-template-rows: auto;
  grid-column-gap: 180px;
  grid-row-gap: 5px;
  width: 100%;
  display: grid; }

.grid-2col-text-photo {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text photo";
  grid-column-gap: 50px;
  display: grid; }

.grid-2col-photo-text {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "photo text";
  grid-column-gap: 50px;
  display: grid; }

.col4-text {
  grid-area: text; }

.col2-text {
  grid-area: text; }

.col2-photo {
  grid-area: photo; }

.grid-callout {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text photo";
  display: grid; }

.grid-main-head {
  grid-area: header; }

.grid-callout-text {
  grid-area: text; }

.grid-callout-photo {
  grid-area: photo; }

.grid-placeself-center {
  place-self: center; }

.grid-placeself-right {
  place-self: right; }

.grid-bottom-1 {
  grid-column: 1/2;
  grid-area: image; }

.grid-bottom-2 {
  grid-column: 2/2;
  grid-area: content; }

.container-portfolio {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  list-style: none;
  margin: 0 auto;
  padding: 0; }

.container-image-dd {
  width: 350px; }

.container-image-300 {
  width: 300px; }

.container-image-500 {
  width: 500px; }

.container-image-portfolio {
  height: 250px;
  max-width: 100%; }

.image-portfolio {
  width: 100%;
  height: 100%; }

.grid-gallery-2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px; }

.grid-callout-2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px; }

.gallery-2col-1 {
  grid-column: 1/2;
  grid-row: 1/auto; }

.gallery-2col-2 {
  grid-column: 2/2;
  grid-row: 1/auto;
  align-self: end; }

.gallery-2col-2t {
  grid-column: 2/2;
  grid-row: 1/auto;
  align-self: start;
  margin-top: 0px; }

.grid-gallery-4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; }

.gallery-4col-1 {
  grid-column: 1/4;
  grid-row: 1/auto; }

.gallery-4col-2 {
  grid-column: 2/4;
  grid-row: 1/auto; }

.gallery-4col-3 {
  grid-column: 3/4;
  grid-row: 1/auto; }

.gallery-4col-4 {
  grid-column: 4/4;
  grid-row: 1/auto; }

.grid-clients-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr); }

.grid-clients-box {
  width: 250px;
  height: 250px;
  background-color: #e6e6e6;
  align-items: center; }

.image {
  position: relative;
  width: 250px;
  height: 250px;
  display: block; }

.image img {
  width: 100%;
  vertical-align: middle; }

.image:after, .image:before {
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s; }

.image:after {
  content: '\A';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); }

.image:before {
  content: attr(data-content);
  display: block;
  width: 100%;
  color: #fff;
  z-index: 1;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.image:hover:after, .image:hover:before {
  opacity: 1; }

.grid-footer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "text1 text2 text3 text4  text5 text6";
  grid-auto-flow: column;
  width: 100%; }

.grid-footer-item-1 {
  grid-column: 1/5;
  grid-row: 1/1;
  grid-area: text1; }

.grid-footer-item-2 {
  grid-column: 2/5;
  grid-row: 1/1;
  grid-area: text2; }

.grid-footer-item-3 {
  grid-column: 3/5;
  grid-row: 1/1;
  grid-area: text3; }

.grid-footer-item-4 {
  grid-column: 4/5;
  grid-row: 1/1;
  grid-area: text4; }

.grid-footer-item-5 {
  grid-column: 5/5;
  grid-row: 1/1;
  grid-area: text5; }

.grid-footer-item-6 {
  grid-column: 6/6;
  grid-row: 1/1;
  grid-area: text6; }

.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

/*******************************
         Mixins
  *******************************/
.lightgreen {
  background-color: #BDCCD4; }

.purple {
  background-color: #78738A; }

.lightpurple {
  background-color: #b8a9c6; }

.green {
  background-color: #87A6AB; }

.yellow-bg {
  background-color: #e3ce71; }

.blue {
  background-color: #aadfe7; }

.offwhite {
  background-color: #E3CEB6; }

.white {
  background-color: #fff; }

.red {
  background-color: #EC1000 !important; }

.ltgrey2 {
  background-color: #e6e6e6 !important; }

.pink {
  background-color: #daa09a; }

.callout-color-dark {
  background-color: #6c8086; }

.photo-content-color {
  background-color: #a6bbc0; }

/*******************************
           Why 
  *******************************/
.text {
  color: black; }

.margin-left-why-60per {
  margin-left: 15%; }

.padding-50 {
  padding: 50px; }

.padding-100 {
  padding: 100px; }

.padding-100-nb {
  padding-left: 100px;
  padding-top: 50px;
  padding-right: 100px; }

.flex-container-small {
  display: flex;
  height: 500px; }

.flex-container-text-small {
  width: 250px;
  padding: 40px; }

.flex-container-services {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: space-between;
  align-items: center; }

.why-top-image {
  background-image: url(/wds_website/src/images/crowdgirls.svg), url(/wds_website/src/images/spotlight.svg);
  background-position: center bottom, left top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

.image-container-content {
  left: -42.0484px;
  top: 0px;
  width: 544.097px;
  height: 306px; }

.flex-item:nth-child(1) {
  order: 0;
  flex: 0 2 auto;
  align-self: auto; }

.flex-item:nth-child(2) {
  order: 0;
  flex: 0 2 auto;
  align-self: auto; }

.flex-item:nth-child(3) {
  order: 0;
  flex: 0 2 auto;
  align-self: auto; }

.flex-item:nth-child(4) {
  order: 0;
  flex: 0 1 auto;
  align-self: auto; }

.flex-sh-400 {
  flex: 0 1 400px; }

.content-block-why {
  width: 350px; }

.content-block-full {
  width: 100%;
  height: auto;
  flex-wrap: nowrap; }

.image_customer_first {
  background: linear-gradient(rgba(0, 244, 183, 0.45), rgba(0, 244, 232, 0.45)); }

/*******************************
           Case Studies
  *******************************/
.flex-section-align-center {
  width: 1200px;
  margin-left: 10%; }

.image-overlay-container {
  text-align: center;
  position: relative; }
  .image-overlay-container > a .overlay-yellow {
    position: absolute;
    width: 128px;
    height: 128px;
    transition: background-color .3s ease-in-out;
    background-color: rgba(251, 180, 36, 0.4); }
    .image-overlay-container > a .overlay-yellow:hover {
      background-color: rgba(251, 180, 36, 0);
      cursor: pointer; }

.why-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 15px;
  width: 85%; }

.why-item {
  flex: 0 33%;
  height: auto;
  /* (100-32*3)/2 */ }

.why-item {
  flex: 0 1 25%;
  height: auto;
  /* (100-32*3)/2 */ }

.why-margin-list-1 {
  margin-left: 0%; }

.why-margin-list-4 {
  margin-left: 20%;
  margin-top: 15px; }

.why-margin-list-3 {
  font-size: 18px;
  margin-left: 20%;
  margin-top: 15px; }

.content-block-cs {
  width: 1200px;
  height: auto;
  font-family: 'Roboto';
  flex-wrap: wrap;
  overflow-wrap: normal; }

.title-case {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.6rem;
  color: #354346b3;
  font-weight: bold; }

.subtitle-case {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5rem;
  line-height: 2.25rem; }

.subtitle-why {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.25;
  font-family: 'Roboto Condensed';
  color: #879397;
  text-align: left; }

.intro-text {
  font-family: 'Roboto Condensed';
  font-size: 31.3px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #000000; }

.photo-container-cs-sb1 {
  width: 100%;
  max-width: 900px;
  height: auto;
  border: 1px solid #000;
  margin-top: 0px; }

.photo-container-cs-sb2 {
  width: 100%;
  max-width: 680px;
  height: auto;
  border: 1px solid #000;
  margin-top: 0px; }

.grid-cs-card {
  display: grid;
  grid-row-gap: 65px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto; }

.grid-cs-card-history {
  display: grid;
  grid-row-gap: 65px;
  grid-column-gap: 50px;
  grid-template-columns: repeat(2, [col] 700px);
  grid-template-rows: auto; }

.cs-card-1 {
  grid-column: 1/ 2;
  grid-row: 1/2; }

.cs-card-2 {
  grid-column: 2/ 2;
  grid-row: 1/2; }

.cs-card-3 {
  grid-column: 1/ 2;
  grid-row: 2/2; }

.cs-card-4 {
  grid-column: 2/ 2;
  grid-row: 2/2; }

.cs-card-right {
  grid-column: 2/2;
  grid-row: auto; }

.grid-2col-list {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 50px;
  display: grid; }

.list-left {
  grid-column: 1/2; }

.list-right {
  grid-column: 2/2; }

.grid-why-list {
  width: 1200px;
  grid-template-columns: repeat(3, 400px);
  grid-row: auto;
  grid-gap: 20px;
  display: grid;
  margin-left: 0%; }

.grid-why-1 {
  grid-column: 1/1;
  grid-row: 2; }

.grid-why-2 {
  grid-column: 2/2;
  grid-row: 2; }

.grid-why-3 {
  grid-column: 3/3;
  grid-row: 2; }

.grid-why-4 {
  grid-column: 1/1;
  grid-row: 3; }

/*******************************
           History
  *******************************/
.container-grid-content-2col {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
  align-self: center;
  display: grid;
  justify-content: space-around;
  padding: 30px; }

.title-container-left {
  margin-right: auto;
  margin-left: 19.25%; }

/*******************************
           History
  *******************************/
.callout-bg-s1 {
  height: 668px; }

/*******************************
          Services
  *******************************/
.sevices-background-section1 {
  display: flex;
  background-color: #AFD5DB;
  border-top-style: outset;
  border-top-color: black;
  border-top-width: 5px;
  border-bottom-style: outset;
  border-bottom-color: black;
  border-bottom-width: 5px; }

.servicespic {
  max-height: 500px;
  margin-right: 20px; }

.services-img {
  width: 1368px;
  height: 768px; }

.grid-2col-callout {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 50px;
  display: grid; }

.callout1 {
  grid-column: 1/2;
  grid-row: 1/1;
  justify-content: center; }

.callout2 {
  grid-column: 2/2;
  grid-row: 1/1;
  justify-content: center; }

.container-grid-2col-photo-services {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  display: grid; }

.grid-2col-photo-content {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 10px;
  display: grid; }

.grid-left {
  grid-column: 1/2;
  grid-row: auto;
  justify-content: center; }

.grid-left-truth {
  grid-column: 1/2;
  grid-row: auto;
  justify-content: center; }

.grid-left-services {
  grid-column: 1/1;
  grid-row: auto;
  justify-content: center; }

.align-self-baseline {
  align-self: baseline; }

.grid-left-contentWrap {
  grid-column: col / span 2;
  grid-row: auto;
  justify-content: center; }

.grid-left-15 {
  grid-column: 1/2;
  grid-row: auto;
  justify-content: center;
  margin-left: 15%; }

.grid-left-45 {
  grid-column: 1/2;
  grid-row: auto;
  justify-content: center;
  margin-left: 45%; }

.grid-left-30 {
  grid-column: 1/2;
  grid-row: 1;
  justify-content: flex-start;
  align-self: flex-start;
  margin-left: 30%; }

.grid-left-25 {
  grid-column: 1/2;
  grid-row: auto;
  margin-left: 20%; }

.grid-right {
  grid-column: 2/2;
  grid-row: auto;
  margin-left: -6%; }

.grid-right-truth {
  grid-column: 2/2;
  grid-row: auto;
  margin-left: -10%; }

.grid-right-css {
  grid-column: 2/2;
  grid-row: auto;
  margin-left: -4%; }

.grid-right-ds {
  grid-column: 2/2;
  grid-row: auto;
  margin-left: -4%;
  align-self: flex-end; }

.grid-right-services {
  grid-column: 2/2;
  grid-row: auto;
  margin-left: -10%; }

.grid-right-15 {
  grid-column: 2/2;
  grid-row: 1;
  margin-left: 15%; }

.grid-left-photo {
  grid-column: 1/2;
  grid-row: 1;
  margin-left: 6%; }

.grid-right-content {
  grid-column: 2/2;
  grid-row: 1;
  justify-content: center; }

.grid-right-contentWrap {
  grid-column: 1/3;
  grid-row: 2;
  justify-content: center; }

.grid-right-photo {
  grid-column: 2/2;
  grid-row: auto;
  justify-content: center;
  align-self: center; }

.grid-services-list {
  width: 1200px;
  grid-template-columns: repeat(3, 400px);
  grid-row: auto;
  grid-gap: 20px;
  display: grid; }

.grid-2things {
  width: 1200px;
  grid-template-columns: repeat(2, 600px);
  grid-gap: 25px;
  display: grid; }

.thing1 {
  grid-column: 1/2; }

.thing2 {
  grid-column: 2/2; }

.grid-services-title {
  grid-column: 1/4;
  grid-row: 1; }

.grid-services-1 {
  grid-column: 1/1;
  grid-row: 2; }

.grid-services-2 {
  grid-column: 2/2;
  grid-row: 2; }

.grid-services-3 {
  grid-column: 3/3;
  grid-row: 2; }

.grid-services-4 {
  grid-column: 1/1;
  grid-row: 3; }

.grid-services-5 {
  grid-column: 2/2;
  grid-row: 3; }

.grid-services-6 {
  grid-column: 3/3;
  grid-row: 3; }

/*******************************
           Portfolio
  *******************************/
.photo-container-border {
  width: 100%; }

.method-callout-image {
  width: 100%;
  margin-left: 17%;
  place-self: center; }

.container-method-callout {
  align-self: center;
  width: 100%;
  margin-left: 36%; }

.strat-container-callout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 15px;
  width: 85%;
  margin-left: 8%; }

.strat-item {
  flex: 0 50%;
  height: auto;
  /* (100-32*3)/2 */ }

.container-text-callout-top {
  width: 60%;
  margin-top: 0;
  margin-left: 0; }

/*******************************
           Portfolio
  *******************************/
.container-image-dd {
  width: 350px; }

.grid-gallery {
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  grid-template-rows: repeat(2, 300px); }

.gallery-1 {
  grid-column: 1/3;
  grid-row: 1/auto; }

.gallery-2 {
  grid-column: 2/3;
  grid-row: 1/auto; }

.gallery-3 {
  grid-column: 3/3;
  grid-row: 1/auto; }

.gallery-4 {
  grid-column: 1/3;
  grid-row: 2/auto; }

.gallery-5 {
  grid-column: 2/3;
  grid-row: 2/auto; }

.gallery-6 {
  grid-column: 3/3;
  grid-row: 2/auto; }

.item-web {
  margin-left: 60%; }

.item {
  width: 100%;
  margin-left: 0; }

/*******************************
           Menu
  *******************************/
.menu {
  background-color: #EC1000; }

ul.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden; }

ul.menu > li {
  float: left; }

.dropbtn {
  display: inline-block;
  color: white !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: .875rem;
  transition-duration: .50s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); }

ul.menu > li.dropdown {
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  color: black;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1; }

.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left; }

.dropdown-content a:hover {
  background-color: #f1f1f1; }

.dropdown:hover .dropdown-content {
  display: block; }

.navbar {
  overflow: hidden;
  font-family: Roboto; }

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none; }

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden; }

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */ }

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: red; }

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left; }

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

a.menu {
  color: #fff !important; }

.home-container-85 {
  font-size: 18px;
  margin-top: 15px;
  width: 85%;
  margin-left: 15%; }

.cs-item {
  flex: 1 1 43%;
  height: auto;
  margin-right: 75px; }

.cs-item-callout {
  flex: 0 0 43%;
  height: auto;
  margin-right: 125px;
  margin-bottom: 0px; }

.home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 15px;
  width: 85%;
  margin-left: 12%; }

.flex-layout-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 75px;
  width: 80%;
  margin-left: -5%; }

.home-container-callout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 15px;
  width: 85%;
  margin-left: 8%; }

.width-250 {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-left: 0%; }

.home-item {
  flex: 0 50%;
  flex-direction: column;
  justify-items: center;
  height: auto;
  /* (100-32*3)/2 */ }

.cs-callout-item {
  flex: 0 45%;
  flex-direction: column;
  justify-items: center;
  height: auto;
  max-height: 600px;
  min-width: 425px;
  margin-bottom: 25px;
  /* (100-32*3)/2 */ }

.callout-content-block {
  width: 95%;
  align-self: center;
  font-family: 'Roboto', 'sans serif';
  font-size: 1.2rem;
  line-height: 1.58;
  margin-left: 6%; }

.home-content-block-lg {
  width: 80%; }

.home-margin {
  margin-left: 12%; }

.why-margin-list-1 {
  margin-left: 18%; }

.home-margin-7 {
  margin-left: 7%; }

.home-callout {
  width: 100%; }

.home-image-container {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: none;
  margin-left: 0; }

.home-image-container-callout {
  width: 118%;
  max-width: 500px;
  height: auto;
  margin-left: 0; }

.cs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 15px;
  width: 100%;
  margin-left: 5%; }

/*******************************
         react-overrides
  *******************************/
.mdl-layout__drawer-button {
  color: white !important; }

.mdl-layout__header-row .mdl-navigation__link {
  color: white !important; }

.mdl-layout__header-row {
  height: 100% !important; }

.mdl-layout.has-scrolling-header .mdl-layout__content {
  overflow-y: visible !important;
  overflow-x: visible !important; }

footer {
  background-color: #daa09a !important;
  color: black !important; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  color: black !important; }

.header {
  background: #EC1000;
  height: 76px !important; }

.mdl-layout__header-row {
  padding: 0 40px 0 5px; }

a:any-link {
  color: -webkit-link;
  text-decoration: none;
  cursor: pointer;
  color: #000; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  padding-left: 20px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 5px !important; }

/*******************************
           Practices
  *******************************/
.choo {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 420px;
  color: purple;
  text-align: center;
  line-height: .50;
  margin-top: 125px; }

.lewie {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 280px;
  color: #d334ff;
  background-color: chartreuse;
  text-align: center;
  line-height: .50;
  margin-top: 125px;
  padding: 300px; }

.chooch {
  font-family: 'RobotoCondensed-Bold', sans-serif;
  font-size: 180px;
  color: blue;
  background-color: #ff95f3;
  text-align: center;
  line-height: .50;
  padding: 100px; }

.woo {
  font-family: 'sans comic', sans-serif;
  font-size: 180px;
  color: #007bff;
  background-color: #00bbff;
  text-align: center;
  line-height: 1.50;
  margin-top: 125px;
  padding: 100px; }

.image-size-20 {
  width: 20%; }

/*****************************************319 break****************************************/
@media (min-width: 319px) {
  .display-none {
    visibility: hidden; }
  .display-remove {
    display: none; }
  .caption {
    text-align: center;
    font-size: .875rem;
    font-style: italic; }
  .item-web {
    margin-left: 37%; }
  .item {
    width: 100%;
    margin-left: 37%; }
  .margin-top-14per {
    margin-top: 0%; }
  .float-left {
    float: none;
    padding: 0; }
  .material-icons {
    opacity: 0.9;
    display: inline-block;
    transition-timing-function: ease-out;
    transition: 0.75s; }
  .photo-container-callout {
    width: 142%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -22%;
    padding-left: 0px; }
  .photo-container-cs-sb2 {
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 1px solid #000;
    margin-top: 0px; }
  .photo-container-cs {
    width: 116%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -22%; }
  .photo-container-border {
    width: 100%;
    height: auto;
    max-width: 550px;
    min-width: 330px; }
  .max-min-330-550 {
    max-width: 550px;
    min-width: 330px; }
  .cs-callout-item {
    flex: 0 1 45%;
    flex-direction: column;
    max-height: 600px;
    min-width: 350px;
    margin-bottom: 25px;
    margin-left: 0%; }
  .method-callout-image {
    width: 135%;
    margin-left: -35%;
    align-self: center; }
  .container-method-callout {
    align-self: center;
    width: 100%;
    margin-left: 36%; }
  .hr-line {
    display: block;
    height: 1px;
    border-top: 1px solid rgba(106, 106, 106, 0.25);
    margin-top: 40px;
    margin-bottom: 40px;
    width: 90%;
    align-self: center; }
  .hr-line-cs {
    display: block;
    height: 1px;
    border-top: 1px solid rgba(106, 106, 106, 0.25);
    margin-top: 40px;
    margin-bottom: 40px;
    width: 90%;
    align-self: center; }
  .callout-content-block {
    width: 112%;
    align-self: center;
    font-family: 'Roboto', 'sans serif';
    font-size: 1.2rem;
    line-height: 1.58;
    margin-left: -2%;
    margin-bottom: 15px; }
  .flex-image-container {
    display: flex;
    flex-direction: flex-start;
    flex: 1 0 auto; }
  .cs-item-callout {
    flex: 0 0 52%;
    height: auto;
    margin-right: 0px;
    margin-bottom: 400px; }
  .flex-image-container-master {
    display: flex;
    flex-direction: flex-start;
    flex: 1 1 420px; }
  .why-item {
    flex: 0 69%;
    height: auto;
    margin-bottom: 25px;
    /* (100-32*3)/2 */ }
  .width-110 {
    width: 118%; }
  .why-margin-list-1 {
    margin-left: 4%; }
  .margin-left-min12per {
    margin-left: 0%; }
  .title-white {
    text-align: center; }
  .margin-left-8sm {
    margin-left: 8%; }
  .why-margin-list-3 {
    margin-left: 12%; }
  .margin-top-30sm {
    margin-top: -30px; }
  .margin-top-7per {
    margin-top: 0%; }
  .border-2-sm {
    border: 2px solid rgba(58, 58, 58, 0.838); }
  .home-item {
    flex: 1 1 51%;
    height: auto;
    /* (100-32*3)/2 */ }
  .cs-item {
    flex: 1 1 51%;
    height: auto;
    /* (100-32*3)/2 */ }
  .cs-margin {
    margin-left: 12%; }
  .home-image-container {
    width: 85%;
    max-width: 500px;
    height: auto; }
  .home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 15px;
    width: 85%;
    margin-left: 5%; }
  .flex-layout-space-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 25px;
    width: 80%;
    margin-left: 3%; }
  .cs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 15px;
    width: 85%;
    margin-left: 16%; }
  .photo-container-feature {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .flex-layout-justified-sa {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }
  .content-text-24 {
    font-family: 'Roboto', 'sans serif';
    font-size: 1.6rem;
    line-height: 1.25; }
  .flex-section-fixed-head {
    display: flex;
    height: 100%;
    min-height: auto;
    background-color: #6c8086; }
  .margin-left-8 {
    margin-left: 8%; }
  .margin-left-5 {
    margin-left: 0; }
  .margin-left-min5sm {
    margin-left: -5%; }
  .margin-top-100sm {
    margin-top: 100px; }
  .margin-left-min30per {
    margin-left: 0%; }
  .margin-left-6sm {
    margin-left: -6%; }
  .margin-left-8 {
    margin-left: 0%; }
  .margin-left-why-60per {
    margin-left: 60%; }
  .why-margin-list-4 {
    margin-left: 4%;
    margin-top: 15px; }
  .width-280px {
    width: 90%; }
  .margin-top-1 {
    margin-top: 0; }
  .margin-right-5 {
    margin-right: 1%; }
  .margin-left-140 {
    margin-left: 75px; }
  .margin-left-14sm {
    margin-left: 14%; }
  .padding-sm-20-10 {
    padding: 20px 20px 5px 10px; }
  .padding-top-50 {
    padding-top: 25px; }
  .title-center {
    line-height: 58.25px;
    font-size: 2.0; }
  .float-left-np {
    float: left;
    padding: 0px 0px 0px 110px; }
  .margin-top-min25sm {
    margin-top: -25px; }
  .margin-bottom-25sm {
    margin-bottom: 25px; }
  .margin-left-50 {
    margin-left: 0%; }
  .margin-top-50cs {
    margin-top: 25px; }
  .margin-left-2 {
    margin-left: 0%; }
  .margin-top-min50per-sm {
    margin-top: -50%; }
  .margin-right-25 {
    margin-right: 0%; }
  .margin-right-35 {
    margin-right: 0%; }
  .margin-left-3 {
    margin-left: -3%; }
  .margin-left-30per {
    margin-left: -7%; }
  .margin-left-5sm {
    margin-left: 5%; }
  .margin-right-25sm {
    margin-left: 25%; }
  .margin-right-25sm {
    margin-left: 25%; }
  .margin-left-15per {
    margin-left: 0%; }
  .margin-left-10sm {
    margin-left: 10%; }
  .margin-left-min10per-sm {
    margin-left: -10%; }
  .margintop-4per {
    margin-top: -0%; }
  .margin-left-4per {
    margin-left: -0%; }
  .width-76sm {
    width: 76%; }
  .padding-20 {
    padding: 0px; }
  .margin-bottom-25sm {
    margin-bottom: 25px; }
  .margin-top-15persm {
    margin-top: -15%; }
  .margin-left-15sm {
    margin-left: 15%; }
  .margin-left-min15sm {
    margin-left: -15%; }
  .margin-left-25sm {
    margin-left: 23%; }
  .margin-left-min25sm {
    margin-left: -25%; }
  .margin-left-2sm {
    margin-left: -2%; }
  .margin-left-2persm {
    margin-left: 2%; }
  .margin-left-min2 {
    margin-left: 0%; }
  .margin-left-4sm {
    margin-left: -4%; }
  .margin-left-min4sm {
    margin-left: -4%; }
  .margin-left-12per {
    margin-left: 0%; }
  .margin-left-12sm {
    margin-left: 12%; }
  .flex-layout-justified-sb-nw {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .flex-layout-row {
    flex-wrap: wrap; }
  .margin-left-20per {
    margin-left: 0%; }
  .container-photo-callout {
    width: 116%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: -7%; }
  .margin-left-6per {
    margin-left: -6px; }
  .margin-left-min15sm {
    margin-left: -15%; }
  .padding-top-6sm {
    padding-top: 0%; }
  .margin-left-10per {
    margin-left: 10%; }
  .margin-top-plus5per {
    margin-top: 5%; }
  .margin-top-10per {
    margin-top: 0%; }
  .margin-top-10persm {
    margin-top: 10%; }
  .margin-top-10 {
    margin-top: 5px; }
  .margin-top-10sm {
    margin-top: 10px; }
  .margin-top-15sm {
    margin-top: 15px; }
  .margin-left-25 {
    margin-left: 25%; }
  .margin-bottom-15 {
    margin-bottom: 15px; }
  .margin-bottom-min15 {
    margin-bottom: 10px; }
  .margin-bottom-100 {
    margin-bottom: 50px; }
  .padding-15sm {
    padding: 15px; }
  .padding-sm {
    padding: 20px; }
  .padding-bottom-50 {
    padding-bottom: 25px; }
  .padding-right-15 {
    padding: 0; }
  .width-65sm {
    width: 65%; }
  .width-85sm {
    width: 85%; }
  .float-right {
    float: right;
    padding: 0px 15px 15px 0px; }
  .header-title-sm {
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 2.75rem;
    letter-spacing: 1.1px;
    line-height: 1;
    text-align: center; }
  .content-block-40 {
    width: 50%; }
  .margin-top-15per {
    margin-top: 0%; }
  .margin-top-2 {
    margin-top: 2px; }
  .margin-top-25per {
    margin-top: .25%; }
  .margin-left-18 {
    margin-left: 0%; }
  .margin-top-min10per {
    margin-top: -10%; }
  .margin-top-5 {
    margin-top: 5px; }
  .margin-top-50 {
    margin-top: 0px; }
  .margin-top-50sm {
    margin-top: 50px; }
  .margin-bottom-50sm {
    margin-bottom: 50px; }
  .margin-top-70 {
    margin-top: 0px; }
  .margin-top-35sm {
    margin-top: 35px; }
  .margin-top-100 {
    margin-top: 10%; }
  .media-margin {
    margin-left: -12%; }
  .media-left-margin {
    margin-left: 12%; }
  .margin-left-32persm {
    margin-left: 32%; }
  .margin-left-325 {
    margin-left: 0px; }
  .visibility {
    display: none; }
  .margin-tb-100 {
    margin: 0px 50px 30px 50px; }
  .header {
    display: none !important; }
  .mobile-menu {
    display: block; }
  .container-section-1-primary {
    display: flex;
    height: auto;
    text-align: center; }
  .content-text-21 {
    font-family: 'Roboto', 'sans serif';
    font-size: 1.3125rem;
    line-height: 1.88; }
  .grid-why-list {
    width: 1200px;
    grid-template-columns: repeat(3, 400px);
    grid-row: auto;
    grid-gap: 20px;
    display: grid;
    margin-left: 60%; }
  /********** layout    *********/
  .width-70 {
    width: 70%; }
  .width-250 {
    width: 110%;
    max-width: auto;
    height: auto;
    margin-left: 0%; }
  .michael-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin-left: 2%; }
  .width-icon {
    width: 100%;
    max-width: 85px;
    height: auto; }
  .margin-top-75 {
    margin-top: 40px; }
  .margin-top-75sm {
    margin-top: 75px; }
  .margin-left-75 {
    margin-left: 10px; }
  .marginleft40per {
    margin-left: 0%; }
  .content-block-auto {
    width: auto;
    max-width: 79%; }
  .content-block-50 {
    width: 80%; }
  .content-block-55 {
    width: 83%; }
  .content-block-60 {
    width: 92%; }
  .content-block-65 {
    width: 90%; }
  .content-block-70 {
    width: 90%; }
  .content-block-75 {
    width: 90%; }
  .content-block-80 {
    width: 95%; }
  .home-content-block-lg {
    width: 75%; }
  .content-block-85 {
    width: 90%; }
  .photo-container-cheer {
    width: 107%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -3%;
    padding-left: 0px; }
  .photo-callout-top {
    width: 116%;
    max-width: 650px;
    height: auto;
    border: 1px solid #000;
    margin-left: -7%; }
  .photo-container {
    width: 116%;
    height: auto;
    max-width: 550px;
    min-width: 330px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -7%; }
  .photo-container-history {
    width: 106%;
    height: auto;
    max-width: 550px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0%; }
  .photo-container-ux {
    width: 110%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -3%;
    padding-left: 0px; }
  .photo-container-cs {
    width: 113%;
    height: auto;
    max-width: 550px;
    min-width: 330px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: -6%; }
  .photo-container-cs-sb1 {
    width: 100%;
    max-width: 900px;
    height: auto;
    border: 1px solid #000;
    margin-top: 20px; }
  .align-self-start {
    align-self: self-start; }
  .content-block-35 {
    width: 90%; }
  .container-image-500 {
    width: 100%;
    max-width: 500px;
    height: auto; }
  .container-image-650 {
    width: 100%;
    max-width: 650px;
    height: auto; }
  .container-image-block {
    width: 100%;
    height: auto; }
  .height-670 {
    height: auto; }
  .margin-top-5per {
    margin-top: 0%; }
  .margin-top-20per {
    margin-top: 0%; }
  .hrline-spacing {
    margin-top: 25px;
    margin-bottom: 25px; }
  .card-case-studies {
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.25);
    width: 90%;
    max-width: 643px;
    border: 1px solid #000; }
  .justify-content-center {
    justify-content: center; }
  /*******      Typography      ******/
  .subtitle {
    font-size: 1.5rem;
    text-align: center; }
  .subtitle-why {
    font-size: 1.5rem;
    margin-top: 15px;
    text-align: left; }
  .title {
    font-size: 2.0rem;
    text-align: center; }
  .header-title {
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 3.12rem;
    line-height: 3rem; }
  .header-title-cs {
    font-size: 2.375rem;
    text-align: center; }
  .logo-cs-mobile {
    width: 17%;
    margin-top: 1%; }
  .title-homepage {
    font-size: 2.12rem;
    line-height: 1.2; }
  .callout-text-lg {
    font-size: 1.5rem;
    line-height: 1.75;
    padding: 25px; }
  .callout-text-white {
    text-align: center;
    padding: 10px; }
  .callout-text-black {
    font-size: 1.25rem; }
  .callout-title-center {
    font-size: 2.0rem;
    text-align: center; }
  .text-align-center-sm {
    text-align: center; }
  .callout-text {
    font-size: 1.25rem;
    text-align: center;
    margin-top: 25px;
    width: 98%; }
  /* GRIDS ****************************************************/
  .grid-callout {
    grid-template-columns: 1fr;
    grid-template-areas: "text" "photo";
    display: grid; }
  .grid-services-list {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas: "text1" "text2" "text3" "text4" "text5" "text6";
    grid-row: auto;
    grid-gap: 20px;
    display: grid; }
  .grid-services-1 {
    grid-column: 1/1;
    grid-row: auto;
    grid-area: text1; }
  .grid-services-2 {
    grid-column: 2/1;
    grid-row: auto;
    grid-area: text2; }
  .grid-services-3 {
    grid-column: 3/1;
    grid-row: auto;
    grid-area: text3; }
  .grid-services-4 {
    grid-column: 4/1;
    grid-row: auto;
    grid-area: text4; }
  .grid-services-5 {
    grid-column: 5/1;
    grid-row: auto;
    grid-area: text5; }
  .grid-services-6 {
    grid-column: 6/1;
    grid-row: auto;
    grid-area: text6; }
  .grid-cs-card {
    display: grid;
    grid-row-gap: 65px;
    grid-column-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto, 1fr);
    justify-items: center; }
  .grid-cs-card-history {
    display: grid;
    grid-row-gap: 65px;
    grid-column-gap: 50px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto, 1fr); }
  .gallery-2col-2t {
    grid-column: 2/2;
    grid-row: 1/auto;
    align-self: start;
    margin-top: 5%; }
  .cs-card-1 {
    grid-column: 1;
    grid-row: 1; }
  .cs-card-2 {
    grid-column: 1;
    grid-row: 2; }
  .cs-card-3 {
    grid-column: 1;
    grid-row: 3; }
  .cs-card-4 {
    grid-column: 1;
    grid-row: 4; }
  .cs-card-right {
    grid-column: 2/2;
    grid-row: auto; }
  .grid-left {
    grid-column: auto;
    grid-row: 1/2;
    justify-content: center; }
  .grid-right, .grid-right-css, .grid-right-ds, .grid-right-photo {
    grid-column: auto;
    grid-row: 2/2;
    margin-left: 0; }
  .margin-top-25sm {
    margin-top: 25px; }
  .grid-2col-text-photo {
    grid-template-columns: 1fr;
    grid-template-areas: "text" "photo";
    grid-gap: 10px;
    display: grid;
    justify-items: center; }
  .grid-2col-photo-text {
    grid-template-columns: 1fr;
    grid-template-areas: "photo" "text";
    grid-gap: 10px;
    display: grid;
    justify-items: center; }
  .grid-2col-photo-content {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
    display: grid;
    justify-items: center; }
  .grid-2col-text {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text text";
    grid-template-rows: auto;
    grid-column-gap: 50px;
    grid-row-gap: 5px;
    display: block; }
  .grid-clients-wrapper {
    grid-template-columns: repeat(1, 1fr);
    align-items: center; }
  .grid-clients-box {
    width: 100%;
    height: auto; }
  .grid-2col-text-center-50 {
    grid-template-columns: 1fr;
    grid-template-areas: "text" "text";
    grid-template-rows: auto;
    grid-row-gap: 5px;
    width: 100%;
    display: grid; }
  .grid-2col-text-center {
    grid-template-columns: 1;
    grid-template-areas: "text" "text";
    grid-template-rows: auto;
    grid-row-gap: 5px;
    width: 100%;
    display: block; }
  .margin-left-min10per {
    margin-left: -10%; }
  .grid-title {
    font-weight: 700;
    grid-column: auto / span 1;
    align-items: center; }
  .grid-right-css {
    grid-column: 1;
    grid-row: auto;
    margin-left: 0%; }
  .grid-left-truth {
    grid-column: 1;
    grid-row: auto;
    justify-content: center; }
  .grid-2things {
    width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 25px;
    display: grid; }
  .thing1 {
    grid-column: 1;
    grid-row: auto; }
  .thing2 {
    grid-column: 1;
    grid-row: auto; }
  .grid-callout-2col {
    display: flex;
    align-self: center;
    flex-direction: column; }
  .grid-gallery-2col {
    display: block; }
  .gallery-2col-1 {
    grid-column: 1/1;
    grid-row: auto;
    min-height: 0px;
    margin-bottom: 50px; }
  .gallery-2col-2 {
    grid-column: 2/1;
    grid-row: auto;
    align-self: start;
    min-height: 500px; }
  /*******  footer    ****************************************/
  .container-footer {
    display: flex;
    flex: 1 0 auto;
    align-items: flex-start;
    padding: 0; }
  .grid-footer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto;
    grid-template-areas: "text1" "text2" "text3" "text4" "text5" "text6";
    padding: 30px;
    margin-top: -2%; }
  .grid-footer-item-1 {
    grid-column: 1/1;
    grid-row: 1/1;
    grid-area: text1;
    margin-top: 25px; }
  .grid-footer-item-2 {
    grid-column: 1/2;
    grid-row: 2/1;
    grid-area: text2;
    margin-top: 25px; }
  .grid-footer-item-3 {
    grid-column: 1/3;
    grid-row: 3/1;
    grid-area: text3;
    margin-top: 25px; }
  .grid-footer-item-4 {
    grid-column: 1/4;
    grid-row: 4/1;
    grid-area: text4;
    margin-top: 25px; }
  .grid-footer-item-5 {
    grid-column: 1/5;
    grid-row: auto;
    grid-area: text5;
    margin-top: 25px;
    margin-left: 0%; }
  .grid-footer-item-6 {
    grid-column: 1/6;
    grid-row: auto;
    grid-area: text6;
    margin-top: 25px; }
  .gallery-2col-2t {
    grid-column: 2/2;
    grid-row: 1/auto;
    align-self: start;
    margin-top: 5%; }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 15px;
    padding: 1px;
    font-family: Roboto; } }

/*****************************************480 break****************************************/
@media only screen and (min-width: 480px) {
  .container-photo-callout {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 0%; }
  .margin-top-7per {
    margin-top: 0%; }
  .photo-container {
    width: 95%;
    height: auto;
    max-width: 550px;
    padding-bottom: 0px;
    padding-top: 10px;
    margin-bottom: 0px;
    margin-left: 4%;
    padding-left: 0px; }
  .margin-top-50 {
    margin-top: 25px; }
  .margin-right-5 {
    margin-right: 5%; }
  .cs-container {
    margin-left: 20%; }
  .photo-container-feature {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; } }

/*****************************************768 break****************************************/
@media only screen and (min-width: 768px) {
  .width-70 {
    width: 60%; }
  .content-block-35 {
    width: 90%; }
  .margin-top-7per {
    margin-top: 0%; }
  .margin-left-75 {
    margin-left: 25px; }
  .margin-left-15per {
    margin-left: 13%; }
  .margin-left-8sm {
    margin-left: 0%; }
  .margin-top-100sm {
    margin-top: 0; }
  .cs-container {
    margin-left: 16%; }
  .margin-top-15sm {
    margin-top: 8%; }
  .container-photo-callout {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 2%; }
  .margin-left-18 {
    margin-left: 28%; }
  .margin-tb-100 {
    margin: 20px 0px 50px 0px; }
  .margin-top-15per {
    margin-top: 0%; }
  .margin-top-50 {
    margin-top: 25px; }
  .margin-top-min10per {
    margin-top: -10%; }
  .hrline-spacing {
    margin-top: 75px;
    margin-bottom: 75px; }
  .padding {
    padding: 10px; }
  .photo-container {
    width: 100%;
    height: auto;
    max-width: 550px;
    padding-bottom: 0px;
    padding-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .float-right {
    float: right;
    padding: 0 50px 5px 25px; }
  .float-left-np {
    float: left;
    padding: 0px 0px 0px 375px; }
  /***** grid    ********************************************/
  .grid-services-list {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row: auto;
    grid-gap: 20px;
    display: grid; }
  .grid-services-1 {
    grid-column: 1;
    grid-row: 2; }
  .grid-services-2 {
    grid-column: 2;
    grid-row: 2; }
  .grid-services-3 {
    grid-column: 1;
    grid-row: 3; }
  .grid-services-4 {
    grid-column: 2;
    grid-row: 3; }
  .grid-services-5 {
    grid-column: 1;
    grid-row: 4; }
  .grid-services-6 {
    grid-column: 2;
    grid-row: 4; }
  .grid-clients-wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr); }
  .grid-clients-box {
    width: 100%;
    height: auto;
    background-color: #e6e6e6;
    align-items: center; }
  .card-case-studies {
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.25);
    width: 634px;
    max-width: 643px; }
  .grid-2col-text-center-50 {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text text";
    grid-template-rows: auto;
    grid-row-gap: 5px;
    width: 50%;
    display: grid; }
  .grid-2col-text-center {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text text";
    grid-template-rows: auto;
    grid-row-gap: 5px;
    width: 50%;
    display: grid; }
  .grid-2col-text {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text text";
    grid-template-rows: auto;
    grid-column-gap: 50px;
    grid-row-gap: 5px;
    display: grid; }
  .grid-title {
    grid-column: auto / span 2;
    align-items: center; }
  .grid-callout-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px; }
  .margin-top-50 {
    margin-top: 25px; }
  .gallery-2col-1 {
    grid-column: 1/2;
    grid-row: 1/auto; }
  .gallery-2col-2 {
    grid-column: 2/2;
    grid-row: 1/auto;
    align-self: start; }
  .container-footer {
    align-items: flex-start; }
  .grid-footer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "text1 text2 text3 text4 text5";
    grid-auto-flow: column;
    width: 100%; }
  .grid-footer-item-1 {
    grid-column: 1/5;
    grid-row: 1/1;
    grid-area: text1; }
  .grid-footer-item-2 {
    grid-column: 2/5;
    grid-row: 1/1;
    grid-area: text2; }
  .grid-footer-item-3 {
    grid-column: 3/5;
    grid-row: 1/1;
    grid-area: text3; }
  .grid-footer-item-4 {
    grid-column: 4/5;
    grid-row: 1/1;
    grid-area: text4; }
  .grid-footer-item-5 {
    grid-column: 5/5;
    grid-row: 1/1;
    grid-area: text5; }
  .grid-why-list {
    width: 1200px;
    grid-template-columns: repeat(3, 400px);
    grid-row: auto;
    grid-gap: 20px;
    display: grid;
    margin-left: 0%; }
  /**************layout     *****/
  .marginleft-40per {
    margin-left: 0%; } }

@media only screen and (min-width: 900px) {
  .container-photo-callout {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 9%; }
  .float-left {
    float: left;
    padding: 0; } }

/*****************************************1024 break****************************************/
@media (min-width: 1024px) {
  .align-self-auto {
    align-self: auto; }
  .content-block-35 {
    width: 35%; }
  .cs-container {
    margin-left: 27%; }
  .content-block-80 {
    width: 60%; }
  .margin-top-50 {
    margin-top: 25px; }
  .margin-top-7per {
    margin-top: 0%; }
  .margin-top-75 {
    margin-top: 25px; }
  .content-block-35 {
    width: 80%; }
  .photo-container {
    width: 100%;
    height: auto;
    max-width: 550px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .material-icons {
    display: none; }
  .mdl-layout__drawer-button {
    display: none !important; }
  .cs-item-callout {
    flex: 0 0 43%;
    height: auto;
    margin-right: 125px;
    margin-bottom: 0px; }
  .header {
    display: block !important;
    transition-timing-function: ease-in;
    transition: 0.75s; }
  .flex-layout-space-between {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 75px;
    width: 85%;
    margin-left: 7%; }
  .cs-callout-item {
    flex: 0 45%;
    flex-direction: column;
    justify-items: center;
    height: auto;
    max-height: 600px;
    min-width: 425px;
    margin-bottom: 25px;
    /* (100-32*3)/2 */ }
  .container-photo-callout {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 0%; }
  .float-right {
    float: right;
    padding: 15px 7px 15px 30px; }
  .marginleft40per {
    margin-left: 40%; }
  .margin-top-25sm {
    margin-top: 0px; }
  .float-left {
    float: left;
    padding: 5px; }
  .photo-container-cheer {
    width: 550px;
    height: 333px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .margin-left-min5sm {
    margin-left: 0%; }
  .margin-tb-100 {
    margin: 50px 75px 50px 5px; }
  .padding {
    padding: 50px; }
  .container-photo-callout {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 0%; }
  .container-photo-top {
    width: 100%;
    max-width: 650px;
    height: auto;
    border: 2px solid #000;
    margin-left: 0%; }
  .margin-left-15 {
    margin-left: 15px; }
  .grid-callout {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text photo";
    display: grid; }
  .grid-2col-text-photo {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text photo";
    grid-column-gap: 50px;
    display: grid;
    justify-items: center; }
  .grid-2col-photo-text {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "photo text";
    grid-column-gap: 50px;
    display: grid;
    justify-items: center; }
  .grid-2col-photo-content {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
    display: grid;
    justify-items: center; }
  .grid-right-photo {
    grid-column: 2;
    grid-row: auto;
    justify-items: center;
    align-self: center; }
  .grid-right-css {
    grid-column: 2;
    grid-row: auto;
    margin-left: -4%; }
  .grid-left {
    grid-column: 1; }
  .grid-footer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "text1 text2 text3 text4 text5";
    grid-auto-flow: column;
    width: 100%; }
  .grid-footer-item-1 {
    grid-column: 1/5;
    grid-row: 1/1;
    grid-area: text1; }
  .grid-footer-item-2 {
    grid-column: 2/5;
    grid-row: 1/1;
    grid-area: text2; }
  .grid-footer-item-3 {
    grid-column: 3/5;
    grid-row: 1/1;
    grid-area: text3; }
  .grid-footer-item-4 {
    grid-column: 4/5;
    grid-row: 1/1;
    grid-area: text4; }
  .grid-footer-item-5 {
    grid-column: 5/5;
    grid-row: 1/1;
    grid-area: text5; }
  .grid-why-list {
    width: 1200px;
    grid-template-columns: repeat(3, 400px);
    grid-row: auto;
    grid-gap: 20px;
    display: grid;
    margin-left: 0%; }
  .container-section-1-primary {
    display: flex;
    height: 270px;
    text-align: center; }
  .header-title {
    font-size: 3.75rem;
    line-height: 1.0; }
  .grid-gallery-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px; } }

/*****************************************1200 break****************************************/
@media (min-width: 1200px) {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 15px;
    padding: 1px;
    font-family: Roboto; }
  .padding-20 {
    padding: 20px; }
  .float-left {
    float: left;
    padding: 15px 5px 15px 15px; }
  .width-70 {
    width: 70%; }
  .flex-image-container {
    display: flex;
    flex-direction: flex-start;
    flex: 1 1 420px;
    border-top: none;
    border-bottom: none; }
  .item-web {
    margin-left: 60%; }
  .item {
    width: 100%;
    margin-left: 0; }
  .flex-layout-space-between {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 75px;
    width: 85%;
    margin-left: 7%; }
  .cs-callout-item {
    flex: 0 45%;
    flex-direction: column;
    justify-items: center;
    height: auto;
    max-height: 600px;
    min-width: 425px;
    margin-bottom: 25px;
    /* (100-32*3)/2 */ }
  .cs-item {
    flex: 1 1 43%;
    height: auto;
    /* (100-32*3)/2 */ }
  .cs-item-callout {
    flex: 1 1 43%;
    height: auto;
    margin-right: 50px;
    /* (100-32*3)/2 */ }
  .callout-title-center {
    font-size: 2rem;
    font-family: 'Roboto Condensed', serif;
    line-height: 2.25rem;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-size: 2.0rem;
    text-align: center; }
  .home-image-container {
    width: 100%;
    max-width: 500px;
    height: auto; }
  .title-white {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 3.05rem;
    line-height: 1.25;
    color: #fff;
    opacity: .8;
    text-align: left; }
  .why-item {
    flex: 0 1 21%;
    height: auto;
    /* (100-32*3)/2 */ }
  .margin-left-8 {
    margin-left: 8%; }
  .border-2-sm {
    border: 0; }
  .padding-sm {
    padding: 0px; }
  .width-65sm {
    width: revert; }
  .width-250 {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-left: 0%; }
  .michael-image {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-left: 0%; }
  .visibility {
    display: block; }
  .float-right {
    float: right;
    padding: 15px 5px 15px 25px; }
  .margin-left-why-60per {
    margin-left: 12%; }
  .margin-bottom-75 {
    margin-bottom: 75px; }
  .margin-top-7per {
    margin-top: -7%; }
  .margin-top-14per {
    margin-top: 14%; }
  .margin-left-140 {
    margin-left: 140px; }
  .margin-left-min12per {
    margin-left: -12%; }
  .margin-left-25 {
    margin-left: 25%; }
  .margin-top-min50per-sm {
    margin-top: 0%; }
  .margin-top-10persm {
    margin-top: 0%; }
  .margin-left-20per {
    margin-left: 20%; }
  .margin-left-18 {
    margin-left: 18%; }
  .margin-top-2 {
    margin-top: 2px; }
  .card-case-studies {
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.25);
    width: 634px;
    max-width: 643px; }
  .grid-cs-card {
    display: grid;
    grid-row-gap: 65px;
    grid-column-gap: 70px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    max-width: 1500px;
    align-self: center; }
  .grid-cs-card-history {
    display: grid;
    grid-row-gap: 65px;
    grid-column-gap: 50px;
    grid-template-columns: repeat(2, [col] auto);
    grid-template-rows: repeat(2, [row] auto); }
  .container-image-block {
    width: 100%;
    height: auto; }
  .cs-card-1 {
    grid-column: 1/ 2;
    grid-row: 1/2; }
  .cs-card-2 {
    grid-column: 2/ 2;
    grid-row: 1/2; }
  .cs-card-3 {
    grid-column: 1/ 2;
    grid-row: 2/2; }
  .cs-card-4 {
    grid-column: 2/ 2;
    grid-row: 2/2; }
  .gallery-2col-2t {
    grid-column: 2/2;
    grid-row: 1/auto;
    align-self: start;
    margin-top: 0%; }
  .cs-card-right {
    grid-column: 2/2;
    grid-row: auto; }
  .header-title-sm {
    text-align: left; }
  .grid-left-truth {
    grid-column: 1/2;
    grid-row: auto;
    justify-content: center; }
  .margin-top-min10per {
    margin-top: 0%; }
  .margin-left-12per {
    margin-left: 15%; }
  .margin-left-min10per {
    margin-left: -10%; }
  .margin-top-5 {
    margin-top: 5px; }
  .margin-left-3 {
    margin-left: 0%; }
  .padding-top-8-sm {
    padding-top: 8%; }
  .padding-top-6sm {
    padding-top: 6%; }
  .home-item {
    flex: 0 50%;
    height: auto;
    margin-bottom: 10%;
    /* (100-32*3)/2 */ }
  .home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 15px;
    width: 85%;
    margin-left: 13%; }
  .why-item {
    flex: 0 25%;
    height: auto;
    margin-bottom: 10%;
    /* (100-32*3)/2 */ }
  .display-none {
    visibility: visible; }
  .callout-content-block {
    width: 95%;
    align-self: center;
    font-family: 'Roboto', 'sans serif';
    font-size: 1.2rem;
    line-height: 1.58;
    margin-left: 6%; }
  .photo-container-cs-sb1 {
    width: 100%;
    max-width: 900px;
    height: auto;
    border: 1px solid #000;
    margin-top: 0px; }
  .photo-container-border {
    width: 100%;
    height: auto;
    max-width: 550px;
    min-width: 330px; }
  .grid-2things {
    width: 1200px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 25px;
    display: grid; }
  .thing1 {
    grid-column: 1/2;
    grid-row: auto; }
  .thing2 {
    grid-column: 2/2;
    grid-row: auto; }
  .margin-left-15per {
    margin-left: 15%; }
  .margin-left-10sm {
    margin-left: 0%;
    margin-top: 0px; }
  .margin-left-15sm {
    margin-left: 0%; }
  .margin-top-25 {
    margin-top: 25px; }
  .margin-top-30sm {
    margin-top: 0; }
  .margin-left-32persm {
    margin-left: 0%; }
  .photo-container-cs-sb2 {
    width: 100%;
    max-width: 680px;
    height: auto;
    border: 1px solid #000;
    margin-top: 0px; }
  .cs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 15px;
    width: 100%;
    margin-left: 5%; }
  .photo-container-cs {
    width: 550px;
    height: 333px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0;
    padding-left: 0px; }
  .photo-container-callout {
    width: 550px;
    height: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .method-callout-image {
    width: 100%;
    margin-left: 17%;
    align-self: center; }
  .container-method-callout {
    align-self: center;
    width: 100%;
    margin-left: 36%; }
  .margin-top-70 {
    margin-top: 70px; }
  .margin-left-min30per {
    margin-left: -30%; }
  .margin-top-50sm {
    margin-top: 0px; }
  .margin-bottom-50sm {
    margin-bottom: 0px; }
  .margin-top-75 {
    margin-top: 75px; }
  .margin-top-10per {
    margin-top: -10%; }
  .margin-left-min15sm {
    margin-left: 0%; }
  .margin-top-15per {
    margin-top: -15%; }
  .margin-top-15sm {
    margin-top: 0px; }
  .margin-top-50 {
    margin-top: 50px; }
  .margin-top-20per {
    margin-top: -20%; }
  .margin-right-35 {
    margin-right: 35%; }
  .margin-right-25sm {
    margin-right: 25%; }
  .margin-right-50 {
    margin-right: 0%; }
  .margin-top-75sm {
    margin-top: 0px; }
  .margin-right-50sm {
    margin-right: 50%; }
  .margin-left-30per {
    margin-left: 30%; }
  .margin-bottom-50 {
    margin-bottom: 50px; }
  .margin-left-2sm {
    margin-left: 0%; }
  .margin-left-2persm {
    margin-left: 0%; }
  .margin-left-4sm {
    margin-left: 0%; }
  .margin-left-6per {
    margin-left: -6%; }
  .margin-tb-100 {
    margin: 50px 275px 50px 5px; }
  .margin-top-1per {
    margin-top: -1%; }
  .home-content-block-lg {
    width: 80%; }
  .float-left-np {
    float: left;
    padding: 0px; }
  .width-110 {
    width: 110%; }
  .photo-container {
    width: 100%;
    height: auto;
    max-width: 550px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .photo-container-ux {
    width: 550px;
    height: 333px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px; }
  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 3.05rem;
    line-height: 1.25;
    color: #879397;
    opacity: .8;
    text-align: left; }
  .subtitle {
    font-size: 1.5rem;
    text-align: left; }
  .margin-left-10per {
    margin-left: 10%; }
  .margin-left-25sm {
    margin-left: 0%; }
  .margin-top-50cs {
    margin-top: 50px; }
  .margin-top-10 {
    margin-top: 10px; }
  .margin-bottom-25sm {
    margin-bottom: 0px; }
  .header-title-sm-center {
    text-align: center; }
  .cs-item-callout {
    flex: 0 0 50%;
    height: auto;
    margin-right: 50px;
    margin-bottom: 0px; }
  .margin-top-5per {
    margin-top: -5%; }
  .margin-left-5 {
    margin-left: 5%; }
  .margin-left-75 {
    margin-left: 75px; }
  .margin-left-50 {
    margin-left: 30%; }
  .margin-right-25 {
    margin-right: 25%; }
  .why-margin-list-3 {
    font-size: 18px;
    margin-left: 20%;
    margin-top: 15px; }
  .media-margin {
    margin-left: 8%; }
  .media-left-margin {
    margin-left: 1%; }
  .margin-top-25per {
    margin-top: -.25%; }
  .header-title {
    font-size: 3.75rem; }
  .content-block-25 {
    width: 25%; }
  .content-block-40 {
    width: 43%; }
  .hr-line {
    display: block;
    height: 1px;
    border-top: 1px solid rgba(106, 106, 106, 0.25);
    margin-top: 75px;
    margin-bottom: 75px;
    width: 60%;
    align-self: center; }
  .content-block-50 {
    width: 50%; }
  .content-block-55 {
    width: 55%; }
  .margin-left-10sm {
    margin-left: 0%; }
  .margin-left-4per {
    margin-left: 4%; }
  .margin-top-1 {
    margin-top: 1%; }
  .hr-line-cs {
    display: block;
    height: 1px;
    border-top: 1px solid rgba(106, 106, 106, 0.25);
    margin-top: 75px;
    margin-bottom: 75px;
    width: 65%;
    align-self: center; }
  .margin-top-35sm {
    margin-top: 0%; }
  .margin-top-50 {
    margin-top: 50px; }
  .width-280px {
    width: 280px; }
  .content-block-60 {
    width: 60%; }
  .content-block-65 {
    width: 65%; }
  .content-block-75 {
    width: 75%; }
  .content-block-80 {
    width: 80%; }
  .content-block-85 {
    width: 85%; }
  .content-block-90 {
    width: 90%; }
  .content-text-21 {
    font-family: 'Roboto' sans-serif;
    font-size: 1.3125rem;
    line-height: 1.88;
    text-align: left; }
  .grid-services-list {
    width: 1200px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row: auto;
    grid-gap: 20px;
    display: grid; }
  .grid-services-1 {
    grid-column: 1;
    grid-row: 2; }
  .grid-services-2 {
    grid-column: 2;
    grid-row: 2; }
  .grid-services-3 {
    grid-column: 3;
    grid-row: 2; }
  .grid-services-4 {
    grid-column: 1;
    grid-row: 3; }
  .grid-services-5 {
    grid-column: 2;
    grid-row: 3; }
  .grid-services-6 {
    grid-column: 3;
    grid-row: 3; }
  .header-title-cs {
    font-size: 3.375rem;
    text-align: center; }
  .display-none {
    display: block; }
  .logo-cs-mobile {
    width: 10%; }
  .grid-left {
    grid-column: 1;
    grid-row: auto;
    justify-content: center; }
  .grid-gallery-2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px; }
  .height-670 {
    height: 400px;
    min-height: 400px; }
  .display-remove {
    display: flex; } }
